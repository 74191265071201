import React from 'react';

const UxUiResponsive = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.422 31.6029H2.24616C1.70871 31.6029 1.19327 31.3908 0.813241 31.0129C0.433206 30.635 0.219727 30.1224 0.219727 29.588V9.72191C0.219727 9.1875 0.433206 8.6749 0.813241 8.29701C1.19327 7.91913 1.70871 7.70703 2.24616 7.70703H33.0426C33.5801 7.70703 34.0955 7.91913 34.4755 8.29701C34.8556 8.6749 35.069 9.1875 35.069 9.72191V15.3979C35.069 15.5861 34.9938 15.7665 34.86 15.8996C34.7262 16.0326 34.5447 16.1074 34.3555 16.1074C34.1663 16.1074 33.9847 16.0326 33.8509 15.8996C33.7171 15.7665 33.642 15.5861 33.642 15.3979V9.72191C33.642 9.64364 33.6264 9.56626 33.5963 9.49395C33.5662 9.42164 33.522 9.35598 33.4664 9.30064C33.4107 9.2453 33.3447 9.20137 33.2719 9.17142C33.1992 9.14147 33.1213 9.12604 33.0426 9.12604H2.24616C2.16745 9.12604 2.08945 9.14147 2.01673 9.17142C1.94401 9.20137 1.87798 9.2453 1.82232 9.30064C1.76666 9.35598 1.72248 9.42164 1.69236 9.49395C1.66224 9.56626 1.64681 9.64364 1.64681 9.72191V29.588C1.64681 29.7461 1.70991 29.8978 1.82232 30.0096C1.93472 30.1214 2.08719 30.1839 2.24616 30.1839H25.422C25.6089 30.1909 25.7864 30.2681 25.9187 30.3997C26.051 30.5313 26.1284 30.7074 26.1355 30.8934C26.1355 31.0815 26.0604 31.2623 25.9265 31.3953C25.7927 31.5284 25.6112 31.6029 25.422 31.6029Z"
      fill="#475467"
    />
    <path
      d="M25.1575 28.3724H0.92578C0.738523 28.3724 0.558903 28.3384 0.426492 28.2778C0.294081 28.2172 0.219727 28.1351 0.219727 28.0495C0.219727 27.9638 0.294081 27.8816 0.426492 27.821C0.558903 27.7605 0.738523 27.7266 0.92578 27.7266H25.1575C25.3448 27.7266 25.5244 27.7605 25.6568 27.821C25.7892 27.8816 25.8636 27.9638 25.8636 28.0495C25.8566 28.1341 25.78 28.2143 25.649 28.2742C25.5181 28.334 25.3425 28.3692 25.1575 28.3724Z"
      fill="#475467"
    />
    <path
      d="M18.7826 12.541H16.773C16.568 12.541 16.3713 12.4476 16.2264 12.2815C16.0814 12.1154 16 11.8902 16 11.6552C16 11.4203 16.0814 11.1951 16.2264 11.029C16.3713 10.8629 16.568 10.7695 16.773 10.7695H18.7826C18.9876 10.7695 19.1841 10.8629 19.3291 11.029C19.4741 11.1951 19.5556 11.4203 19.5556 11.6552C19.5556 11.8902 19.4741 12.1154 19.3291 12.2815C19.1841 12.4476 18.9876 12.541 18.7826 12.541Z"
      fill="#475467"
    />
    <path
      d="M24.9676 39.9936H10.5012C10.3093 39.9936 10.1253 39.9192 9.98966 39.7867C9.85401 39.6543 9.77783 39.4747 9.77783 39.2874V38.2705C9.78241 38.1061 9.84562 37.9484 9.95654 37.8247C10.0675 37.7009 10.2191 37.6188 10.3854 37.5926L14.3203 36.8581H15.0147V32.7336C15.0219 32.5485 15.1003 32.3731 15.2345 32.2422C15.3686 32.1112 15.5485 32.0343 15.738 32.0273H19.9044C20.0962 32.0273 20.2802 32.1018 20.4159 32.2342C20.5515 32.3667 20.6277 32.5463 20.6277 32.7336V36.8581H21.4089L25.1991 37.5926C25.3625 37.6195 25.5108 37.7027 25.6169 37.8271C25.723 37.9514 25.7801 38.1087 25.7778 38.2705V39.2874C25.7785 39.3878 25.7573 39.4871 25.7157 39.5788C25.674 39.6705 25.6128 39.7523 25.5362 39.8192C25.4595 39.886 25.3692 39.9363 25.2712 39.9664C25.1732 39.9965 25.0697 40.0057 24.9676 39.9936ZM12.6422 38.5812H22.7977L21.0617 38.2422H19.7886C19.6018 38.2349 19.4252 38.1574 19.2957 38.0257C19.1663 37.894 19.0941 37.7185 19.0943 37.536V33.4115H16.3456V37.4512C16.3456 37.6385 16.2694 37.8181 16.1338 37.9505C15.9981 38.083 15.8141 38.1574 15.6223 38.1574H14.4071L12.6422 38.5812Z"
      fill="#475467"
    />
    <path
      d="M44.4355 37.3427H42.6757C42.4376 37.3427 42.2092 37.2961 42.0408 37.213C41.8724 37.13 41.7778 37.0173 41.7778 36.8999C41.7778 36.7824 41.8724 36.6698 42.0408 36.5868C42.2092 36.5037 42.4376 36.457 42.6757 36.457H44.4355C44.6736 36.457 44.9021 36.5037 45.0704 36.5868C45.2388 36.6698 45.3334 36.7824 45.3334 36.8999C45.3334 37.0173 45.2388 37.13 45.0704 37.213C44.9021 37.2961 44.6736 37.3427 44.4355 37.3427Z"
      fill="#475467"
    />
    <path
      d="M47.2431 40.0004H38.979C38.7783 40.0004 38.5857 39.9221 38.4438 39.7827C38.3019 39.6433 38.2222 39.4543 38.2222 39.2571V23.9152C38.2222 23.718 38.3019 23.529 38.4438 23.3896C38.5857 23.2502 38.7783 23.1719 38.979 23.1719H47.2431C47.4439 23.1719 47.6364 23.2502 47.7783 23.3896C47.9202 23.529 47.9999 23.718 47.9999 23.9152V39.2571C47.9999 39.4543 47.9202 39.6433 47.7783 39.7827C47.6364 39.9221 47.4439 40.0004 47.2431 40.0004ZM39.7358 38.5138H46.5167V24.6585H39.7358V38.5138Z"
      fill="#475467"
    />
    <path
      d="M35.6715 36.4589H33.6619C33.4569 36.4589 33.2602 36.3655 33.1153 36.1994C32.9703 36.0333 32.8889 35.8081 32.8889 35.5732C32.8889 35.3383 32.9703 35.1131 33.1153 34.947C33.2602 34.7809 33.4569 34.6875 33.6619 34.6875H35.6715C35.8765 34.6875 36.0731 34.7809 36.218 34.947C36.363 35.1131 36.4445 35.3383 36.4445 35.5732C36.4445 35.8081 36.363 36.0333 36.218 36.1994C36.0731 36.3655 35.8765 36.4589 35.6715 36.4589Z"
      fill="#475467"
    />
    <path
      d="M42.1621 39.9961H27.2887C26.7362 39.9961 26.2065 39.7689 25.8158 39.3649C25.4252 38.9608 25.2058 38.4127 25.2058 37.8413V16.9636C25.2058 16.3922 25.4252 15.8441 25.8158 15.44C26.2065 15.036 26.7362 14.8088 27.2887 14.8088H42.1621C42.4381 14.8048 42.7121 14.8575 42.9682 14.964C43.2243 15.0705 43.4574 15.2288 43.6539 15.4293C43.8504 15.6298 44.0065 15.8686 44.113 16.132C44.2195 16.3954 44.2743 16.6781 44.2743 16.9636V23.5788C44.2743 23.78 44.1971 23.9729 44.0595 24.1152C43.922 24.2575 43.7354 24.3375 43.5409 24.3375C43.3464 24.3375 43.1598 24.2575 43.0223 24.1152C42.8847 23.9729 42.8075 23.78 42.8075 23.5788V16.9636C42.7999 16.7919 42.7286 16.6297 42.6084 16.511C42.4882 16.3922 42.3283 16.3259 42.1621 16.3261H27.2887C27.1253 16.3261 26.9685 16.3933 26.853 16.5128C26.7375 16.6323 26.6726 16.7946 26.6726 16.9636V37.8413C26.6726 38.0103 26.7375 38.1726 26.853 38.2921C26.9685 38.4116 27.1253 38.4788 27.2887 38.4788H42.1621C42.3566 38.4788 42.5432 38.5588 42.6807 38.7011C42.8183 38.8433 42.8955 39.0362 42.8955 39.2374C42.8955 39.4387 42.8183 39.6316 42.6807 39.7738C42.5432 39.9161 42.3566 39.9961 42.1621 39.9961Z"
      fill="#475467"
    />
    <path
      d="M34.6982 21.398C34.3439 21.4043 33.9959 21.3049 33.6987 21.1127C33.4016 20.9205 33.1688 20.6441 33.0304 20.3192C32.8919 19.9942 32.854 19.6355 32.9216 19.2889C32.9891 18.9424 33.1591 18.6241 33.4096 18.3745C33.6602 18.1249 33.9798 17.9553 34.3276 17.888C34.6754 17.8206 35.0354 17.8585 35.3615 17.9965C35.6876 18.1345 35.965 18.3665 36.1579 18.6626C36.3508 18.9587 36.4505 19.3055 36.4442 19.6585C36.436 20.1173 36.2495 20.5549 35.9238 20.8795C35.5981 21.204 35.1587 21.3899 34.6982 21.398ZM34.6982 19.4685C34.6719 19.4624 34.6445 19.463 34.6186 19.4704C34.5927 19.4778 34.5691 19.492 34.55 19.5109C34.531 19.5299 34.5172 19.5531 34.5097 19.5789C34.5023 19.6047 34.5016 19.6323 34.5077 19.6585C34.5077 19.8483 34.8569 19.8483 34.8569 19.6585C34.8569 19.4687 34.7934 19.4685 34.6982 19.4685Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiResponsive;
